import React, { useEffect, useState } from "react";
import "../styles/LandingPage.css";
import logo from "../Assets/imgs/logo.webp";
import image from "../Assets/imgs/main-image.webp";

type Props = {
  otp: {
    one: string;
    two: string;
    three: string;
    four: string;
  };
  handleChangeOTP: (e: any, index: any) => void;
  inputRef: React.RefObject<HTMLInputElement>[];
  handleBackspace: (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => void;
  error: string;
  showError: boolean;
  handleSubmitBtn: () => void;
  showLoader: boolean;
  timer: number;
  onResendOtpPressed: () => void;
};

const OtpPage = (props: Props) => {
  return (
    <div className="landingpage otp">
      <div className="main-content">
        <div className="logo">
          <img src={logo} width={153} height={71} alt="logo" />
        </div>

        <div className="main-img-wraper">
          <div className="image">
            <img src={image} width={340} height={295} alt="Ufone Magic Club" />
          </div>
        </div>

        <div className="form otp">
          <div className="text">
            PLAY AND LEARN WITH YOUR FAVORITE DISNEY CHARACTERS!
          </div>

          <div className="sub-otp-text">Enter the 4 digit OTP received.</div>

          <div className="otp-inputs">
            <input
              type="tel"
              name="one"
              maxLength={1}
              autoFocus
              value={props.otp.one}
              onChange={(e) => {
                props.handleChangeOTP(e, 0);
              }}
              ref={props.inputRef[0]}
              onKeyDown={(e) => {
                props.handleBackspace(e, 0);
              }}
            />
            <input
              type="tel"
              name="two"
              maxLength={1}
              value={props.otp.two}
              onChange={(e) => {
                props.handleChangeOTP(e, 1);
              }}
              ref={props.inputRef[1]}
              onKeyDown={(e) => {
                props.handleBackspace(e, 1);
              }}
            />
            <input
              type="tel"
              name="three"
              maxLength={1}
              value={props.otp.three}
              onChange={(e) => {
                props.handleChangeOTP(e, 2);
              }}
              ref={props.inputRef[2]}
              onKeyDown={(e) => {
                props.handleBackspace(e, 2);
              }}
            />
            <input
              type="tel"
              name="four"
              maxLength={1}
              value={props.otp.four}
              onChange={(e) => {
                props.handleChangeOTP(e, 3);
              }}
              ref={props.inputRef[3]}
              onKeyDown={(e) => {
                props.handleBackspace(e, 3);
              }}
            />
          </div>

          {props.showError ? (
            <>
              <p className="invalid">{props.error}</p>
            </>
          ) : (
            <></>
          )}
          <div className="resend">
            {props.timer > 0 ? (
              <>Resend OTP in 00:{props.timer}</>
            ) : (
              <>
                Didn't receive code?
                <div className="resend-otp" onClick={props.onResendOtpPressed}>
                  Resend OTP
                </div>
              </>
            )}
          </div>
          {props.showLoader ? (
            <div className="lp-loader">Loading...</div>
          ) : (
            <button
              onClick={() => {
                props.handleSubmitBtn();
              }}
            >
              Verify
            </button>
          )}

          <div className="form-text">
            <p> You can cancel your subscription at any time</p>
          </div>

          <div className="condition">
            <a href="/terms" target="_blank">
              Terms & Conditions
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpPage;
