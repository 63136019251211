import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import tagManagerEvents from "../utils/GoogleTagManager";
import { fetchApiGet, fetchApiPost } from "../utils/FetchApi";
import { ApiName } from "../utils/ApiName";
import AwarenessPage from "../component/AwarenessPage";

type Props = {
  token: string | null;
};
let adNetwork = "";

const AwarenessScreen = (props: Props) => {
  useEffect(() => {
    if (props.token) {
      getHe();
    }
  }, [props.token]);

  const getHe = () => {
    fetch("http://ufone.magicclub.mobi/partners/api/v1/digitology/he-msisdn")
      .then((response) => response.json()) // Parse the JSON from the response
      .then((data) => {
        if (data?.code === "0" && data?.msisdn) {
          const url = new URL(window.location.href);
          const searchParams = new URLSearchParams(url.search);
          const _utmSource: string | null = searchParams.get("utm_source");

          if(_utmSource){
            checkUtm(_utmSource)
          }
          getFlow(_utmSource, data.msisdn);
        } else {
          window.location.replace("http://ufone.magicclub.mobi/store/identify?utm_source=ads_digitology");
        }
      })
      .catch((error) => {
        window.location.replace("http://ufone.magicclub.mobi/store/identify?utm_source=ads_digitology");
      });
  };

  const checkUtm = async (utmSource: string | null) => {
    try {
      let response = await fetchApiGet(props.token, ApiName.utmList);
      console.log("UTM response", response);
      if (response.status === 0) {
        {
          response.response.map((e: any) => {
            if (utmSource === e.sf_utm) {
              adNetwork = e.ad_network;
            }
          });
        }
      }
    } catch (err) {
      console.log("UTM failed: " + err);
    }
  };

  const getFlow = async (utmSource: string | null, msisdn: string | null) => {
    try {
      let data = {
        utm_source: utmSource,
        msisdn: msisdn,
      };
      let response = await fetchApiPost(
        props.token,
        ApiName.awarenessFlow,
        data
      );
      if (response.status === 0) {
        if (
          adNetwork.toLowerCase() === "tiktok" ||
          adNetwork.toLowerCase() === "tik tok"
        ) {
          console.warn("aaa");
          tagManagerEvents("subscribe", "optimusTikTok");
        } else if (adNetwork.toLowerCase() === "google") {
          console.warn("bbb");
          tagManagerEvents("subscribe", "optimus");
        } else {
          console.warn("ccc");
          tagManagerEvents("subscribe", utmSource);
        }
        setTimeout(() => {
          window.location.replace("http://ufone.magicclub.mobi/store/identify?utm_source=ads_digitology");
        }, 3000);
      } else {
        window.location.replace("http://ufone.magicclub.mobi/store/identify?utm_source=ads_digitology");
      }
     
    } catch (error) {
      console.log(error);
      window.location.replace("http://ufone.magicclub.mobi/store/identify?utm_source=ads_digitology");
    }
  };
  return <AwarenessPage />;
};

export default AwarenessScreen;
