import React from "react";
import "../styles/AwarenessPage.css";
import MainImage from "../Assets/imgs/awareness-main-img.webp";


const AwarenessPage = () => {
  return (
    <>
      <div className="awareness-page">
        <div className="image">
          <img
            src={MainImage}
            width={340}
            height={598}
            alt="awareness main image"
          />
        </div>
      </div>
    </>
  );
};

export default AwarenessPage;
