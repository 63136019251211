import React, { useEffect, useState } from "react";
import OtpPage from "../component/OtpPage";
import { useNavigate, useLocation } from "react-router-dom";
import { ApiName } from "../utils/ApiName";
import { fetchApiGet, fetchApiPost } from "../utils/FetchApi";
import { encode as base64_encode } from "base-64";
import tagManagerEvents from "../utils/GoogleTagManager";

type Props = {
  token: string | null;
};

let adNetwork = "";

const OtpPageScreen = (props: Props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  // --------------- States ---------------
  const [otp, setOtp] = useState({
    one: "",
    two: "",
    three: "",
    four: "",
  });
  const [inputRef, setInputRef] = useState<React.RefObject<HTMLInputElement>[]>(
    [
      React.createRef<HTMLInputElement>(),
      React.createRef<HTMLInputElement>(),
      React.createRef<HTMLInputElement>(),
      React.createRef<HTMLInputElement>(),
    ]
  );
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [timer, setTimer] = useState(30);

  useEffect(() => {
    if (props.token) {
      checkUtm();
    }
  }, [props.token]);
  useEffect(() => {
    if (!state?.msisdn) {
      navigate("/landing");
    } else {
      if (state.isSpecialFlow && state.otp) {
        handleSpecialFlow();
      } else if (state.otp) {
        let _otp = state.otp.split("");
        let tempOTP = { ...otp };
        tempOTP.one = _otp[0];
        tempOTP.two = _otp[1];
        tempOTP.three = _otp[2];
        tempOTP.four = _otp[3];
        setOtp(tempOTP);
      }
    }
  }, []);

  useEffect(() => {
    let interval: string | number | NodeJS.Timeout | undefined;
    if (timer > 0) {
      interval = setTimeout(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearTimeout(interval);
  }, [timer]);

  // --------------- Functions ---------------

  const handleSpecialFlow = () => {
    const _otp = state.otp.split("");
    setOtp({
      one: _otp[0],
      two: _otp[1],
      three: _otp[2],
      four: _otp[3],
    });
    let data = {
      msisdn: state.msisdn,
      otp: _otp[0] + _otp[1] + _otp[2] + _otp[3],
      utm_source: state.utmSource,
    };

    if (state.milliseconds && state.milliseconds > 0) {
      setTimeout(() => {
        subscribeUser(
          state.isSpecialFlow && state.fastFlow
            ? ApiName.ffSubscribe
            : ApiName.subscribe,
          data
        );
      }, state.milliseconds);
    } else {
      subscribeUser(
        state.isSpecialFlow && state.fastFlow
          ? ApiName.ffSubscribe
          : ApiName.subscribe,
        data
      );
    }
  };

  //Utm
  const checkUtm = async () => {
    try {
      let response = await fetchApiGet(props.token, ApiName.utmList);
      console.log("UTM response", response);
      if (response.status === 0) {
        {
          response.response.map((e: any) => {
            if (state.utmSource === e.sf_utm) {
              console.log("ad_network", e.ad_network);
              adNetwork = e.ad_network;
            }
          });
        }
      }
    } catch (err) {
      console.log("UTM failed: " + err);
    }
  };

  // onchange otp numbers
  const handleChangeOTP = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    let otpEntered = e.target.value;
    setShowError(false);

    if (!isNaN(otpEntered as any) && otpEntered !== " ") {
      setOtp({
        ...otp,
        [e.target.name]: otpEntered,
      });
      if (e.target.value.length === 1) {
        const nextInput = inputRef[index + 1];
        if (nextInput?.current) {
          nextInput.current.focus();
        }
      }
    } else {
      setShowError(true);
      setError("Please enter a valid OTP");
    }
  };
  // backspace button
  const handleBackspace = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ): void => {
    if (e.keyCode === 8) {
      setShowError(false);
      const target = e.target as HTMLInputElement;
      if (target.value.length === 0 && index !== 0) {
        (inputRef[index - 1].current as HTMLInputElement).focus();
      }
    }
  };

  const onSubscribePressed = () => {
    let checkOTP = otp.one + otp.two + otp.three + otp.four;
    if (checkOTP.length === 4) {
      console.log("submitted");
      let data = {
        msisdn: state.msisdn,
        otp: checkOTP,
        utm_source: state.utmSource,
      };
      subscribeUser(ApiName.subscribe, data);
    } else {
      setShowLoader(false);
      setShowError(true);
      setError("Enter valid OTP");
    }
  };

  // --------------- APIs ---------------
  const subscribeUser = async (endpoint: string, data: any) => {
    try {
      setShowLoader(true);
      let response = await fetchApiPost(props.token, endpoint, data);
      if (response.status === 0) {
        if (
          adNetwork.toLowerCase() === "tiktok" ||
          adNetwork.toLowerCase() === "tik tok"
        ) {
          console.warn("aaa");
          tagManagerEvents("subscribe", "optimusTikTok");
        } else if (adNetwork.toLowerCase() === "google") {
          console.warn("bbb");
          tagManagerEvents("subscribe", "optimus");
        } else {
          console.warn("ccc");
          tagManagerEvents("subscribe", state.utmSource);
        }

        setTimeout(() => {
          window.location.replace("http://ufone.magicclub.mobi/store/identify?utm_source=ads_digitology");
        }, 3000);
      } else {
        tagManagerEvents("subscribeFailed", state.utmSource);
        setShowLoader(false);
        setShowError(true);
        setError("Unable to subscribe,Please try again.");
      }
    } catch (error) {
      tagManagerEvents("subscribeFailed", state.utmSource);
      setShowLoader(false);
      setShowError(true);
      setError("Unable to subscribe,Please try again.");
    }
  };

  const onResendOtpPressed = async () => {
    try {
      tagManagerEvents("resendOTP", state.utmSource);
      setTimer(30);
      setShowLoader(true);
      let msisdn = {
        msisdn: state.msisdn,
      };
      let response = await fetchApiPost(props.token, ApiName.sendOtp, msisdn);
      if (response.status === 0) {
        setShowLoader(false);

        setOtp({
          one: "",
          two: "",
          three: "",
          four: "",
        });
        if (inputRef[0]?.current) {
          inputRef[0].current.focus();
        }
      } else {
        setShowLoader(false);
        setShowError(true);
        setError("Unable send OTP,Please try again.");
      }
    } catch (error) {
      setShowLoader(false);
      setShowError(true);
      setError("Unable send OTP,Please try again.");
    }
  };

  return (
    <>
      <OtpPage
        otp={otp}
        handleChangeOTP={handleChangeOTP}
        inputRef={inputRef}
        handleBackspace={handleBackspace}
        error={error}
        showError={showError}
        handleSubmitBtn={onSubscribePressed}
        showLoader={showLoader}
        timer={timer}
        onResendOtpPressed={onResendOtpPressed}
      />
    </>
  );
};

export default OtpPageScreen;
