export const ApiName = {
  generateToken: "authenticateWeb",
  getFlow: "getFlow",
  awarenessFlow: "awarenessFlow",
  sendOtp: "sendOtpWeb",
  verifyOtp: "verifyOtpWeb",
  subscribe: "subscribeWeb",
  ffSubscribe: "ffSubscribe",
  utmList:"utmList",
  
};
