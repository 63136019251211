import TagManager from "react-gtm-module";
const tagManagerEvents = (
  eventName: any,
  utm_source: string | null | undefined
) => {
  let utmSource = "";
  if (utm_source) utmSource = utm_source;
  const tagManagerArgs = {
    dataLayer: {
      event: eventName,
      utm_source: utmSource,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};
const landingTagMangerEvent = (eventName: any, utm_source: string) => {};
export default tagManagerEvents;
