import React, { useEffect, useState } from "react";
import Landingpage from "../component/Landingpage";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchApiGet, fetchApiPost } from "../utils/FetchApi";
import { ApiName } from "../utils/ApiName";
import tagManagerEvents from "../utils/GoogleTagManager";

type Props = {
  token: string | null;
};
const LandingPageScreen = (props: Props) => {
  const navigate = useNavigate();

  const [phoneNo, setPhoneNo] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [utmSource, setUtmSource] = useState<string | null>(null);

  useEffect(() => {
    if (props.token) {
      getHe();
    }
  }, [props.token]);

  const getHe = () => {
    fetch("http://ufone.magicclub.mobi/partners/api/v1/digitology/he-msisdn")
      .then((response) => response.json()) // Parse the JSON from the response
      .then((data) => {
        if (data?.code === "0" && data?.msisdn) {
          const url = new URL(window.location.href);
          const searchParams = new URLSearchParams(url.search);
          const _utmSource: string | null = searchParams.get("utm_source");
          setUtmSource(_utmSource);
          getFlow(_utmSource, data.msisdn);
        } else {
          tagManagerEvents("wifiUser", utmSource);
          setErrorMsg(
            "Access to the Ufone Magical Club is exclusively available for Ufone 4G users."
          );
        }
      })
      .catch((error) => {
        tagManagerEvents("wifiUser", utmSource);
        // Handle any errors that occurred during the fetch
        console.error("Error fetching data:", error);
        setErrorMsg(
          "Access to the Ufone Magical Club is exclusively available for Ufone 4G users."
        );
      });
  };

  const getFlow = async (utmSource: string | null, msisdn: string | null) => {
    try {
      let data = {
        utm_source: utmSource,
        msisdn: msisdn,
      };
      let response = await fetchApiPost(props.token, ApiName.getFlow, data);
      if (response.status === 0) {
        if (
          response.response.isSpecialFlow === true &&
          response.response.msisdn !== "" &&
          response.response.otp !== ""
        ) {
          // special flow
          const data = {
            isSpecialFlow: response.response.isSpecialFlow,
            fastFlow: response.response.fastFlow,
            msisdn: response.response.msisdn,
            otp: response.response.otp,
            milliseconds: response.response.milliseconds,
            signIn: response.response.signIn,
            package: response.response.package,
            network: response.response.network,
            utmSource: utmSource,
          };

          navigate("/pin", { state: data });
        } else if (
          response.response.isSpecialFlow === false &&
          response.response.msisdn
        ) {
          // 4g user without special flow
          tagManagerEvents("heUser", utmSource);
          setPhoneNo(response.response.msisdn);
          if (response.response.signIn) {
            window.location.replace("http://ufone.magicclub.mobi/store/identify?utm_source=ads_digitology");
          }
        } else {
          // wifi user
          setErrorMsg(
            "Access to the Ufone Magical Club is exclusively available for Ufone 4G users."
          );

          tagManagerEvents("wifiUser", utmSource);
        }
      }
    } catch (error) {
      console.log("handleGetFlow Error: ", error);
    }
  };

  const handlePhoneNoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (!value || (value.length <= 10 && value.match(/^3\d{0,9}$/))) {
      setErrorMsg("");
      setPhoneNo(e.target.value);
    } else {
    }
  };

  const onSendOtpPressed = async () => {
    try {
      if (phoneNo) {
        setShowLoader(true);
        let data = {
          msisdn: phoneNo,
        };
        let response = await fetchApiPost(props.token, ApiName.sendOtp, data);
        if (response.status === 0) {
          const data = {
            msisdn: phoneNo,
            otp: response.response,
            utmSource: utmSource,
          };
          navigate("/pin", { state: data });
        } else {
          setErrorMsg("Something Went Wrong, Please Reload.");
        }
        setShowLoader(false);
      } else {
        setErrorMsg(
          "Access to the Ufone Magical Club is exclusively available for Ufone 4G users."
        );
      }
    } catch (error) {
      console.log("sendotp", error);
      setErrorMsg("Something Went Wrong, Please Reload.");
    }
  };

  return (
    <Landingpage
      handlePhoneNoChange={handlePhoneNoChange}
      onSendOtpPressed={onSendOtpPressed}
      phoneNo={phoneNo}
      errorMsg={errorMsg}
      showLoader={showLoader}
    />
  );
};

export default LandingPageScreen;
