import React from 'react';
// import '../styles/PrivacyPolicy.css';


function PrivacyPolicy() {
    return (
        <div className="jazz_discount_outer">
            <h2>Privacy Policy</h2>

            <h3>Privacy Policy</h3>
            <p>
                We are committed to protecting your privacy. If we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement. We may update these Terms from time to time through this website. We recommend checking this page from time to time to ensure you are happy with any changes.<br></br>
            </p>

            <h3>What data do we collect?</h3>
            <p>
                If necessary, we collect the following data: name, contact details, including email address and other relevant data. What do we do with the information collected? - We use this information to better understand your needs and provide you with a better service, in particular for the following reasons:<br></br>
            </p>


            <h3>Internal records</h3>
            <p>
                We may use the information to improve our products and services. We may from time to time send promotional emails to the email address which you have provided to inform you about new products, special offers or other information which may be of interest to you. We may use the information to tailor our website to your interests.<br></br>
            </p>

            <h3>Security</h3>
            <p>
                We strive to ensure the security of your data. In order to prevent unauthorized access or disclosure, we have put in place appropriate physical, electronic and managerial procedures to safeguard and secure the information we collect online.<br></br>
            </p>

            <h3>How we use cookies</h3>
            <p>
                A cookie is a small file that asks you to store on your computer's hard drive. If you agree, a file will be added and a cookie will help analyze web traffic or inform you when you visit a particular website. Cookies enable web applications to respond to you as an individual. The web application can adapt its processes to your needs and remember information about your settings.<br></br>
            </p>

            <p>
                We use cookies to record communications and find out which pages you use. This helps us analyze data about website traffic and improve our website in order to tailor it to our customers' needs. We only use this information for statistical analysis purposes and the data is then removed from the system. Overall, cookies help us provide you with a better website by tracking which websites you find useful and which you do not. Cookies do not give us access to your computer or provide us with any information about you, other than the data you choose to share with us.<br></br>
            </p>

            <h3>Personal information</h3>
            <p>
                We will not sell, share or rent your personal information to third parties unless we have your consent or are required by law to do so. If the information we collect about you is incorrect or incomplete, please email us as soon as possible. We will promptly correct any information we believe is incorrect.<br></br>
            </p>
        </div>
    )
}

export default PrivacyPolicy
